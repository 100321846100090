<template>
    <v-card flat>
        <v-card-text class="mt-5 pa-0">
            <v-stepper v-model="step" vertical color="primary">
                <v-stepper-step color="primary" :complete="step > 1" step="1">
                    Select
                    <small class="mt-1" v-if="step === 1">Select one or more columns from the selected table</small>
                </v-stepper-step>
                <v-stepper-content step="1">
                    <div class="d-flex flex-column">
                        <v-row align="center">
                            <v-col cols="12" sm="2">
                                <v-subheader class="font-weight-bold">From</v-subheader>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <div class="d-flex flex-column justify-start">
                                    <v-select
                                        :items="currentTables"
                                        v-model="selectedTable"
                                        item-text="short_id"
                                        item-value="short_id"
                                        :label="fetchingTableColumnsForQuery ? 'Fetching table data' : 'Selected table'"
                                        dense
                                        :loading="fetchingTableColumnsForQuery || tablesFetching"
                                        :disabled="fetchingTableColumnsForQuery"
                                        hide-details
                                        outlined
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        @change="setTableInRouteQuery($event)">
                                        <template v-slot:item="currentTables">
                                            <div class="d-flex flex-column py-2">
                                                <span class="subtitle-2">{{ currentTables.item.short_id }}</span>
                                                <span class="caption">{{ currentTables.item.description }}</span>
                                            </div>
                                        </template>
                                    </v-select>
                                    <v-dialog v-if="selectedTable && selectedTableDescription" max-width="500">
                                        <template v-slot:activator="{ on }">
                                            <div>
                                                <v-btn color="info" v-on="on" text small>
                                                    <v-icon class="mr-1" small>info</v-icon>
                                                    About this table
                                                </v-btn>
                                            </div>
                                        </template>

                                        <v-card>
                                            <v-card-title>
                                                <span class="secondary--text">{{ selectedTable }}</span>
                                            </v-card-title>
                                            <v-divider class="mb-2"></v-divider>
                                            <v-card-text>
                                                <span>{{ selectedTableDescription }}</span>
                                            </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="my-5" v-if="fetchingTableColumnsForQuery || tablesFetching" no-gutters>
                            <v-col cols="6">
                                <v-skeleton-loader v-for="n in 8" :key="n" type="list-item"></v-skeleton-loader>
                            </v-col>
                            <v-col cols="6">
                                <v-skeleton-loader v-for="n in 8" :key="n" type="list-item"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <template v-else-if="selectedTable">
                            <div class="my-5 d-flex flex-sm-column flex-md-row align-start w-100">
                                <div :style="$vuetify.breakpoint.mdAndUp ? 'width:52.5%' : 'width:100%'" class="d-flex align-center">
                                    <div :style="$vuetify.breakpoint.mdAndUp ? 'width:95%' : 'width:100%'">
                                        <div class="d-flex align-center justify-space-between flex-wrap w-100">
                                            <span class="font-weight-bold secondary--text">Available Columns ({{ allColumns.length }})</span>
                                            <v-btn
                                                small
                                                :disabled="allColumnsAreSelected"
                                                @click="addAllColumns"
                                                color="primary"
                                                text
                                                e2e-snapshot-tables-select-all>
                                                Select all
                                            </v-btn>
                                        </div>
                                        <v-text-field hide-details v-model="availableColumnSearch" append-icon="search" label="Filter" outlined></v-text-field>

                                        <v-virtual-scroll :bench="2" :items="columnData" height="500" item-height="64">
                                            <template v-slot:default="{ item }">
                                                <v-list-item @click="addRemoveColumn(item.colName)" :key="item.colName">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <div class="d-flex align-center">
                                                                {{ item.colName }}
                                                                <v-chip outlined color="secondary" class="ml-1" x-small>{{ item.type }}</v-chip>
                                                            </div>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>{{ item.desc }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-icon class="ma-0 pa-0" v-if="addedColumns.includes(item.colName)" color="green lighten-1">
                                                            check_circle
                                                        </v-icon>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        </v-virtual-scroll>
                                    </div>
                                    <div v-if="$vuetify.breakpoint.mdAndUp" style="width: 5%" class="d-flex justify-center">
                                        <v-icon>forward</v-icon>
                                    </div>
                                </div>
                                <div :style="$vuetify.breakpoint.mdAndUp ? 'width:47.5%' : 'width:100%'" :class="$vuetify.breakpoint.smAndDown ? 'mt-8' : ''">
                                    <div class="d-flex align-center justify-space-between flex-wrap w-100">
                                        <span class="font-weight-bold secondary--text">Selected Columns ({{ addedColumns.length }})</span>
                                        <v-btn small :disabled="!addedColumns.length" @click="addedColumns = []" color="error" text>Remove all</v-btn>
                                    </div>
                                    <v-text-field v-model="addedColumnSearch" hide-details append-icon="search" label="Filter" outlined></v-text-field>

                                    <v-virtual-scroll :bench="2" :items="filteredAddedColumns" height="500" item-height="64">
                                        <template v-slot:default="{ item }">
                                            <v-list-item :key="item.colName">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <div class="d-flex align-center">
                                                            {{ item.colName }}
                                                            <v-chip outlined color="secondary" class="ml-1" x-small>{{ item.type }}</v-chip>
                                                        </div>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.desc }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn small @click="removeColumn(item.colName)" icon>
                                                        <v-icon color="red lighten-2">clear</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </template>
                                    </v-virtual-scroll>
                                </div>
                            </div>
                            <div class="d-flex justify-center">
                                <v-dialog max-width="800">
                                    <template v-slot:activator="{ on }">
                                        <v-btn :disabled="!addedColumns.length || fetchingTableColumnsForQuery" class="mr-1" v-on="on" outlined text>
                                            Preview Selected Columns
                                        </v-btn>
                                    </template>
                                    <v-card flat>
                                        <v-card-title>
                                            <span class="text-uppercase secondary--text ml-1">Selected columns preview</span>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                            <SnapshotTablePreview
                                                :columnsData="columnDataForPreview"
                                                :headersData="columnHeadersForPreview"
                                                :fetchingData="fetchingPreviewData"
                                                :tableName="selectedTable" />
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                                <v-btn
                                    :disabled="!selectedTable || !addedColumns.length || fetchingTableColumnsForQuery"
                                    color="primary"
                                    @click="step = 2"
                                    e2e-snapshot-tables-continue>
                                    Continue
                                    <v-icon>navigate_next</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </div>
                </v-stepper-content>
                <v-stepper-step color="primary" :complete="step > 2" step="2">
                    Filter
                    <small class="mt-1" v-if="step === 2">Filter on the selected columns for rows that meet certain criteria</small>
                </v-stepper-step>
                <v-stepper-content step="2">
                    <div class="mb-12">
                        <v-btn @click="addFilter" text class="mr-3 font-weight-bold" color="secondary">+ add filter</v-btn>
                        <div v-if="addedFilters.length">
                            <div v-for="(filter, index) in addedFilters" :key="filter.index" class="mb-2 pt-5">
                                <v-form v-model="addedFilters[index].valid">
                                    <div class="d-flex align-center">
                                        <v-btn @click="removeFilter(filter.index)" small color="error" icon class="mr-3">
                                            <v-icon>clear</v-icon>
                                        </v-btn>
                                        <v-row align="end" justify="start" flex-wrap no-gutters>
                                            <v-col class="mr-2" cols="2">
                                                <v-select
                                                    @change="clearFilterDataUponColumnChange(index)"
                                                    required
                                                    :rules="[rules.required]"
                                                    dense
                                                    v-model="addedFilters[index].column"
                                                    :items="addedColumns"
                                                    label="column"
                                                    outlined></v-select>
                                            </v-col>
                                            <v-col v-if="addedFilters[index].column" cols="2">
                                                <v-select
                                                    dense
                                                    @change="clearFilterFileData(index)"
                                                    :items="columnFilters(filter.column)"
                                                    v-model="addedFilters[index].filter"
                                                    class="mr-2"
                                                    label="condition"
                                                    required
                                                    :rules="[rules.required]"
                                                    outlined></v-select>
                                            </v-col>
                                            <v-col
                                                v-if="
                                                    addedFilters[index].column &&
                                                    addedFilters[index].filter &&
                                                    ![operatorTypes.NULL_OPERATOR, operatorTypes.NON_NULL_OPERATOR].includes(addedFilters[index].filter) &&
                                                    !(
                                                        dateAndTimeColumns.includes(colType(addedFilters[index].column)) &&
                                                        addedFilters[index].filter !== operatorTypes.IN_OPERATOR
                                                    ) &&
                                                    addedFilters[index].file === null
                                                "
                                                class="mr-2"
                                                cols="2">
                                                <v-text-field
                                                    :rules="
                                                        numericColumns.includes(colType(addedFilters[index].column)) &&
                                                        addedFilters[index].filter !== operatorTypes.IN_OPERATOR
                                                            ? [rules.required, rules.mustBeFloat]
                                                            : [rules.required]
                                                    "
                                                    required
                                                    autocomplete="new-value"
                                                    :label="addedFilters[index].filter == operatorTypes.IN_OPERATOR ? 'comma seperated values' : 'value'"
                                                    v-model="addedFilters[index].inputField1"
                                                    dense
                                                    outlined></v-text-field>
                                            </v-col>
                                            <v-col
                                                v-if="
                                                    addedFilters[index].column &&
                                                    addedFilters[index].filter &&
                                                    addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR &&
                                                    numericColumns.includes(colType(addedFilters[index].column))
                                                "
                                                cols="1"
                                                outlined>
                                                <div style="height: 48px" class="d-flex align-center justify-center mx-1">
                                                    <span class="text-uppercase">and</span>
                                                </div>
                                            </v-col>
                                            <v-col
                                                v-if="
                                                    addedFilters[index].column &&
                                                    addedFilters[index].filter &&
                                                    addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR &&
                                                    numericColumns.includes(colType(addedFilters[index].column))
                                                "
                                                cols="2">
                                                <v-text-field
                                                    required
                                                    :rules="
                                                        numericColumns.includes(colType(addedFilters[index].column)) &&
                                                        addedFilters[index].filter !== operatorTypes.IN_OPERATOR
                                                            ? [rules.required, rules.mustBeFloat]
                                                            : [rules.required]
                                                    "
                                                    label="value"
                                                    v-model="addedFilters[index].inputField2"
                                                    dense
                                                    outlined></v-text-field>
                                            </v-col>
                                            <v-col
                                                v-if="
                                                    addedFilters[index].column &&
                                                    addedFilters[index].filter &&
                                                    addedFilters[index].filter == operatorTypes.IN_OPERATOR
                                                "
                                                cols="1">
                                                <div class="d-flex align-center">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on" class="d-flex align-center">
                                                                <v-file-input
                                                                    dense
                                                                    v-model="addedFilters[index].file"
                                                                    @change="uploadFile($event, index)"
                                                                    accept=".txt"
                                                                    label="Upload file"
                                                                    :hide-input="addedFilters[index].file === null"
                                                                    outlined></v-file-input>
                                                            </div>
                                                        </template>
                                                        <span>Upload a plain text file (.txt), having one unquoted value per line.</span>
                                                    </v-tooltip>
                                                    <v-tooltip v-if="filter.fileError" bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon class="ml-1" v-on="on" color="error">info</v-icon>
                                                        </template>
                                                        <span>File parsing error.</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-col>
                                            <v-col
                                                v-if="
                                                    addedFilters[index].column &&
                                                    addedFilters[index].filter &&
                                                    ![operatorTypes.NULL_OPERATOR, operatorTypes.NON_NULL_OPERATOR, operatorTypes.IN_OPERATOR].includes(
                                                        addedFilters[index].filter
                                                    ) &&
                                                    dateColumns.includes(colType(addedFilters[index].column))
                                                "
                                                class="mr-2"
                                                cols="1">
                                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            v-model="addedFilters[index].datePicker1"
                                                            :label="addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR ? 'Start date' : 'date'"
                                                            readonly
                                                            v-on="on"
                                                            dense
                                                            :rules="[rules.required]"
                                                            required
                                                            outlined></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="addedFilters[index].datePicker1" no-title scrollable></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col
                                                v-if="
                                                    addedFilters[index].column &&
                                                    addedFilters[index].filter &&
                                                    ![operatorTypes.NULL_OPERATOR, operatorTypes.NON_NULL_OPERATOR, operatorTypes.IN_OPERATOR].includes(
                                                        addedFilters[index].filter
                                                    ) &&
                                                    timeColumns.includes(colType(addedFilters[index].column))
                                                "
                                                cols="1">
                                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            v-model="addedFilters[index].timePicker1"
                                                            :label="addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR ? 'Start time' : 'time'"
                                                            readonly
                                                            v-on="on"
                                                            dense
                                                            :rules="[rules.required]"
                                                            required
                                                            outlined></v-text-field>
                                                    </template>
                                                    <div class="px-2">
                                                        <time-picker
                                                            v-model="addedFilters[index].timePicker1"
                                                            use-seconds
                                                            format="24hr"
                                                            scrollable></time-picker>
                                                    </div>
                                                </v-menu>
                                            </v-col>
                                            <v-col
                                                v-if="
                                                    addedFilters[index].column &&
                                                    addedFilters[index].filter &&
                                                    dateAndTimeColumns.includes(colType(addedFilters[index].column)) &&
                                                    addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR
                                                "
                                                cols="1">
                                                <div style="height: 48px" class="d-flex align-center justify-center mx-1">
                                                    <span class="text-uppercase">and</span>
                                                </div>
                                            </v-col>
                                            <v-col
                                                v-if="
                                                    addedFilters[index].column &&
                                                    addedFilters[index].filter &&
                                                    dateColumns.includes(colType(addedFilters[index].column)) &&
                                                    addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR
                                                "
                                                cols="1">
                                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            v-model="addedFilters[index].datePicker2"
                                                            label="End date"
                                                            readonly
                                                            v-on="on"
                                                            dense
                                                            :rules="[rules.required]"
                                                            required
                                                            outlined></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="addedFilters[index].datePicker2" no-title scrollable></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col
                                                v-if="
                                                    addedFilters[index].column &&
                                                    addedFilters[index].filter &&
                                                    timeColumns.includes(colType(addedFilters[index].column)) &&
                                                    addedFilters[index].filter === operatorTypes.BETWEEN_OPERATOR
                                                "
                                                class="ml-2"
                                                cols="1">
                                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            v-model="addedFilters[index].timePicker2"
                                                            label="End time"
                                                            readonly
                                                            v-on="on"
                                                            dense
                                                            :rules="[rules.required]"
                                                            required
                                                            outlined></v-text-field>
                                                    </template>
                                                    <div class="px-2">
                                                        <time-picker
                                                            use-seconds
                                                            v-model="addedFilters[index].timePicker2"
                                                            format="24hr"
                                                            scrollable></time-picker>
                                                    </div>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-form>
                            </div>
                        </div>
                    </div>
                    <v-btn :disabled="!!addedFilters.length && !areFiltersValid" color="primary" @click="step = 3" e2e-snapshot-tables-skip-step-2>
                        <div class="d-flex align-center" v-if="!!addedFilters.length">
                            Continue
                            <v-icon>navigate_next</v-icon>
                        </div>
                        <div class="d-flex align-center" v-else>
                            Skip
                            <v-icon>skip_next</v-icon>
                        </div>
                    </v-btn>
                    <v-btn @click="step = 1" text>Back</v-btn>
                </v-stepper-content>
                <v-stepper-step color="primary" :complete="step > 3" step="3">
                    Aggregate
                    <small class="mt-1" v-if="step === 3">Aggregate values in selected columns, forming groups based on the values of other columns</small>
                </v-stepper-step>
                <v-stepper-content step="3">
                    <div class="mb-12">
                        <v-btn @click="addAggregationCondition" text class="mr-3 font-weight-bold" color="secondary">+ Add Aggregation Function</v-btn>
                        <div v-if="addedAggregationConditions.length">
                            <div v-for="(aggregator, index) in addedAggregationConditions" :key="index" class="d-flex align-center">
                                <v-btn color="error" @click="removeAggregationCondition(aggregator.index)" icon><v-icon>clear</v-icon></v-btn>
                                <v-form v-model="aggregator.valid">
                                    <v-row align="center" no-gutters>
                                        <v-col cols="3" class="mr-2">
                                            <v-select
                                                label="Function"
                                                :items="filteredAggregationFunctions"
                                                v-model="aggregator.aggFunction"
                                                required
                                                :rules="[rules.required]"
                                                @change="aggregator.aggregationColumns = getAggregationColumnNames(aggregator.aggFunction)"
                                                outlined></v-select>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select
                                                label="Column"
                                                :items="aggregator.aggregationColumns"
                                                v-model="aggregator.column"
                                                required
                                                :rules="[rules.required]"
                                                outlined></v-select>
                                        </v-col>
                                        <v-col cols="1">
                                            <div class="d-flex justify-center text-uppercase">as</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="aggregator.alias"
                                                label="Optional alias.."
                                                :rules="[rules.aliasNameDifferentThanColumNames, rules.uniqueAggregationAliasName]"
                                                outlined></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </div>
                        </div>
                    </div>
                    <div class="mb-6">
                        <v-btn
                            @click="addAggregationFilter"
                            :disabled="!addedAggregationConditions.length || !validAggregationConditions.length || onlyAggregationWithoutGroupBy"
                            text
                            class="font-weight-bold"
                            color="secondary">
                            + Add Aggregation Filter
                        </v-btn>
                        <div v-for="(aggregationFilter, index) in addedAggregationFilters" :key="index" class="d-flex align-center">
                            <v-btn color="error" @click="removeAggregationFilter(aggregationFilter.index)" icon><v-icon>clear</v-icon></v-btn>
                            <v-form v-model="aggregationFilter.valid">
                                <v-row align="center" no-gutters>
                                    <v-col cols="4">
                                        <v-select
                                            label="Column"
                                            :items="validAggregationConditions"
                                            v-model="aggregationFilter.column"
                                            @change="aggregationFilter.valueType = getAggregationFilterColumnType(aggregationFilter.column)"
                                            outlined></v-select>
                                    </v-col>
                                    <v-col class="mx-2" cols="3">
                                        <v-select
                                            label="Filter"
                                            :items="Object.keys(aggregationFilterOperators)"
                                            v-model="aggregationFilter.filter"
                                            required
                                            :rules="[rules.required]"
                                            outlined></v-select>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field
                                            required
                                            v-model="aggregationFilter.value"
                                            :rules="
                                                numericColumns.includes(aggregationFilter.valueType) || /^(COUNT|AVG)\(.*/.test(aggregationFilter.column)
                                                    ? [rules.required, rules.mustBeFloat]
                                                    : [rules.required]
                                            "
                                            outlined></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>
                    </div>
                    <v-btn
                        @click="step = 4"
                        :disabled="
                            (!!addedAggregationConditions.length && !areAggregationConditionsValid) ||
                            (!!addedAggregationFilters.length && !areAggregationFiltersValid)
                        "
                        color="primary"
                        e2e-snapshot-tables-skip-step-3>
                        <div class="d-flex align-center" v-if="!!addedAggregationConditions.length">
                            Continue
                            <v-icon>navigate_next</v-icon>
                        </div>
                        <div class="d-flex align-center" v-else>
                            Skip
                            <v-icon>skip_next</v-icon>
                        </div>
                    </v-btn>
                    <v-btn @click="step = 2" text>Back</v-btn>
                </v-stepper-content>
                <v-stepper-step color="primary" :complete="step > 4" step="4">
                    Sort
                    <small class="mt-1" v-if="step === 4">Sort data in either ascending or descending according to one or more columns</small>
                </v-stepper-step>
                <v-stepper-content step="4">
                    <div class="mb-12">
                        <v-btn @click="addOrderingCondition" :disabled="onlyAggregationWithoutGroupBy" text class="mr-3 font-weight-bold" color="secondary">
                            + Add Ordering Column
                        </v-btn>
                        <div v-if="addedOrderingConditions.length">
                            <div v-for="(order, index) in addedOrderingConditions" :key="index" class="d-flex align-center">
                                <v-btn color="error" @click="removeOrderingCondition(order.index)" icon><v-icon>clear</v-icon></v-btn>
                                <v-row align="center">
                                    <v-col cols="6">
                                        <v-form v-model="order.valid">
                                            <v-select v-model="order.column" :items="orderByColumnNames" label="Columns" required :rules="[rules.required]">
                                                <template v-slot:prepend-item>
                                                    <v-text-field autofocus v-model="orderBySearch" class="px-4" label="Filter" outlined></v-text-field>
                                                </template>
                                            </v-select>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="6">
                                        <div class="d-flex align-center">
                                            <v-radio-group v-model="order.order" row>
                                                <v-radio label="Ascending" value="ASC"></v-radio>
                                                <v-radio label="Descending" value="DESC"></v-radio>
                                            </v-radio-group>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                    <v-btn
                        @click="step = 5"
                        :disabled="!!addedOrderingConditions.length && !areOrderingConditionsValid"
                        color="primary"
                        e2e-snapshot-tables-skip-step-4>
                        <div class="d-flex align-center" v-if="!!addedOrderingConditions.length">
                            Continue
                            <v-icon>navigate_next</v-icon>
                        </div>
                        <div class="d-flex align-center" v-else>
                            Skip
                            <v-icon>skip_next</v-icon>
                        </div>
                    </v-btn>
                    <v-btn text @click="step = 3">Back</v-btn>
                </v-stepper-content>
                <v-stepper-step color="primary" :complete="step > 5" step="5">
                    Row limit
                    <small class="mt-1" v-if="step === 5">Specify the number of records to return</small>
                </v-stepper-step>
                <v-stepper-content step="5">
                    <v-form class="my-3" v-model="validRowNumberForm">
                        <div style="max-width: 300px">
                            <v-text-field
                                :disabled="onlyAggregationWithoutGroupBy"
                                outlined
                                dense
                                required
                                v-model="numberRows"
                                :rules="[rules.required, rules.maxAndIntQueryRows]"></v-text-field>
                        </div>
                    </v-form>
                    <v-btn @click="step = 6" :disabled="!validRowNumberForm" color="primary" e2e-snapshot-tables-continue-step-5>
                        Continue
                        <v-icon>navigate_next</v-icon>
                    </v-btn>
                    <v-btn @click="step = 4" text>Back</v-btn>
                </v-stepper-content>
                <v-stepper-step color="primary" step="6">
                    Final query
                    <small class="mt-1" v-if="step === 6">Review the final query before submitting it</small>
                </v-stepper-step>
                <v-stepper-content step="6">
                    <div v-if="step === 6" class="d-flex flex-column">
                        <div style="max-width: 600px; border-style: solid; border-width: thin">
                            <CodeHighlight language="sql" :code="query" />
                        </div>
                        <div class="d-flex align-center mt-3">
                            <QuerySubmit
                                :mode="queryModeOptions.FULL_WITH_SAVE_MODE"
                                buttonName="submit for data export"
                                :iconShaped="false"
                                @success="querySubmitted"
                                :forDialog="true"
                                :query="query"
                                class="mr-1"></QuerySubmit>
                            <GetTableData :sqlText="query" />
                            <v-btn @click="step = 5" text>Back</v-btn>
                        </div>
                    </div>
                </v-stepper-content>
            </v-stepper>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { queryData } from '@/mixins/query'
import eventBus from '@/components/EventBus'
import TimePicker from '@/components/TimePicker.vue'
const CodeHighlight = () => import('@/components/CodeHighlight')
const QuerySubmit = () => import('../components/TheSnapshotSubmitQuery.vue')
const GetTableData = () => import('../components/TheSnapshotTableGetData')
const SnapshotTablePreview = () => import('../components/TheSnapshotTablePreview')
function initialState() {
    return {
        step: 1,
        addedFilters: [],
        addedOrderingConditions: [],
        addedAggregationConditions: [],
        addedAggregationFilters: [],
        aggregationFilterIndex: 0,
        filterIndex: 0,
        orderingColumnIndex: 0,
        aggregationColumnIndex: 0,
        availableColumnSearch: '',
        addedColumnSearch: '',
        orderBySearch: '',
        tableBeingFetched: '',
        validRowNumberForm: true,
        fetchingPreviewData: false,
        latestFetchedTable: null,
        fetchingTableColumnsForQuery: false,
        numberRows: 10000,
        queryColumnsList: [],
        rules: {
            required: value => !!value || 'Required.',
            nonEmpty: value => value.length > 0 || 'Select at least one item.',
            aliasNameDifferentThanColumNames: value => !this.addedColumns.includes(value) || 'Cannot use selected column names.',
            uniqueAggregationAliasName: value =>
                !value || this.aggregationConditionsAliasNames.filter(alias => value === alias).length === 1 || 'Alias name already used',
            maxAndIntQueryRows: value => (!!parseInt(value) && value >= 0 && value <= 10000000) || 'Please insert a number between 1 and 10000000 rows',
            mustBeFloat: value => Number(value) === parseFloat(value) || 'Must be a number'
        },
        aggregationFilterOperators: { 'Greater than': '>', 'Less than': '<', Equal: '=' },
        selectedTable: null,
        addedColumns: [],
        numericColumns: ['NUMBER', 'FLOAT'],
        dateAndTimeColumns: ['DATE', 'TIME', 'TIMESTAMP_NTZ'],
        dateColumns: ['DATE', 'TIMESTAMP_NTZ'],
        timeColumns: ['TIME', 'TIMESTAMP_NTZ'],
        columnTypes: {
            STRING_COLUMN: 'VARCHAR',
            DATE_COLUMN: 'DATE',
            TIME_COLUMN: 'TIME',
            TIMESTAMP_COLUMN: 'TIMESTAMP_NTZ',
            NUMERIC_COLUMN: 'NUMBER',
            FLOAT_COLUMN: 'FLOAT'
        },
        operatorTypes: {
            EQUAL_OPERATOR: 'equal',
            NON_EQUAL_OPERATOR: 'not equal',
            LESS_THAN_OPERATOR: 'less than',
            GREATER_THAN_OPERATOR: 'greater than',
            IN_OPERATOR: 'in',
            BETWEEN_OPERATOR: 'between',
            LESS_OR_EQUAL_OPERATOR: 'less than or equal',
            GREATER_OR_EQUAL_OPERATOR: 'greater than or equal',
            NULL_OPERATOR: 'is null',
            NON_NULL_OPERATOR: 'is not null'
        },
        operatorsMappings: {
            'greater than': '>',
            'less than': '<',
            equal: '=',
            'not equal': '!=',
            in: 'in',
            between: 'between',
            'less than or equal': '<=',
            'greater than or equal': '>='
        },
        selectedTablePreviewData: [],
        selectedTablePreviewHeaders: [],
        aggregationFunctions: { Average: 'AVG', Sum: 'SUM', Minimum: 'MIN', Maximum: 'MAX', Count: 'COUNT' }
    }
}
export default {
    name: 'QueryDialog',
    components: { QuerySubmit, CodeHighlight, GetTableData, SnapshotTablePreview, TimePicker },
    mixins: [queryData],
    data: () => {
        return initialState()
    },
    computed: {
        ...mapState('snapshotStore', ['currentTables', 'tablesFetching', 'previewFetching', 'previewData']),
        allColumnsAreSelected() {
            return this.allColumns.length > 0 && this.allColumns.length === this.addedColumns.length
        },
        selectedTableDescription() {
            if (this.selectedTable && this.currentTables.length) {
                return this.currentTables.find(table => table.short_id === this.selectedTable).description
            }
            return null
        },
        columnDataForPreview() {
            if (this.addedColumns.length) {
                return this.selectedTablePreviewData.map(column => {
                    const filteredColumnArray = Object.entries(column).filter(col => this.addedColumns.includes(col[0].toUpperCase()))
                    const filteredColumnObject = Object.fromEntries(filteredColumnArray)
                    return filteredColumnObject
                })
            }
            return []
        },
        columnHeadersForPreview() {
            if (this.addedColumns.length) {
                return this.selectedTablePreviewHeaders.filter(header => this.addedColumns.includes(header.value.toUpperCase()))
            }
            return []
        },
        filteredAddedColumns() {
            if (this.addedColumnSearch) {
                return this.selectedColumnsData.filter(
                    column =>
                        column.colName.toLowerCase().includes(this.addedColumnSearch.toLowerCase()) ||
                        column.desc.toLowerCase().includes(this.addedColumnSearch.toLowerCase())
                )
            }
            return this.selectedColumnsData
        },
        allColumns() {
            if (this.queryColumnsList && this.queryColumnsList.length) {
                const columnsData = this.queryColumnsList.map(function (column) {
                    const filteredData = {
                        colName: column.short_id,
                        type: column.coltype.includes('(') ? column.coltype.substring(0, column.coltype.indexOf('(')) : column.coltype,
                        desc: column.description ? column.description : 'No description available'
                    }
                    return filteredData
                })
                return columnsData
            }
            return []
        },
        onlyAggregationWithoutGroupBy() {
            if (this.aggregationColumnNames.length && this.addedColumns.length && this.aggregationColumnNames.length === this.addedColumns.length) {
                return true
            }
            return false
        },
        aggregationColumnNames() {
            if (this.addedAggregationConditions) {
                const colNames = this.addedAggregationConditions.map(condition => condition.column)
                const uniqueColNames = [...new Set(colNames)]
                return uniqueColNames
            }
            return []
        },
        validAggregationConditions() {
            if (this.addedAggregationConditions) {
                return this.addedAggregationConditions
                    .filter(condition => condition.valid)
                    .map(condition => {
                        return condition.alias ? condition.alias : `${this.aggregationFunctions[condition.aggFunction]}(${JSON.stringify(condition.column)})`
                    })
            }
            return []
        },
        aggregateConditionsColumnMappings() {
            if (this.addedAggregationConditions.length) {
                return this.addedAggregationConditions.map(condition => {
                    return {
                        colName: condition.column,
                        function: condition.alias
                            ? condition.alias
                            : `${this.aggregationFunctions[condition.aggFunction]}(${JSON.stringify(condition.column)})`,
                        aggregator: condition.aggFunction
                    }
                })
            }
            return []
        },
        orderByColumnNames() {
            const filteredColumns = this.orderBySearch ? this.addedColumns.filter(col => col.toLowerCase().includes(this.orderBySearch)) : this.addedColumns
            if (this.validAggregationConditions.length) {
                const columns = filteredColumns
                    .filter(column => !this.aggregationColumnNames.includes(column))
                    .concat(this.validAggregationConditions)
                    .sort()
                return columns
            }
            return filteredColumns
        },
        selectedColumnsData() {
            return this.allColumns.filter(column => this.addedColumns.includes(column.colName))
        },
        areFiltersValid() {
            const isValid = filter => !filter.valid
            if (this.addedFilters.length && !this.addedFilters.some(isValid)) {
                return true
            }
            return false
        },
        areOrderingConditionsValid() {
            const isValid = condition => !condition.valid
            if (this.addedOrderingConditions.length && !this.addedOrderingConditions.some(isValid)) {
                return true
            }
            return false
        },
        areAggregationConditionsValid() {
            const isValid = condition => !condition.valid
            if (this.addedAggregationConditions.length && !this.addedAggregationConditions.some(isValid)) {
                return true
            }
            return false
        },
        areAggregationFiltersValid() {
            const isValid = condition => !condition.valid
            if (this.addedAggregationFilters.length && !this.addedAggregationFilters.some(isValid)) {
                return true
            }
            return false
        },
        aggregationConditionsAliasNames() {
            if (this.addedAggregationConditions.length) {
                const nonNullAliases = this.addedAggregationConditions.filter(condition => condition.alias).map(condition => condition.alias)
                return nonNullAliases
            }
            return []
        },
        filteredAggregationFunctions() {
            if (this.selectedColumnsData) {
                const selectedColumnTypes = this.selectedColumnsData.map(column => column.type)
                const availableAggregationFunctions = this.numericColumns.some(type => selectedColumnTypes.includes(type))
                    ? ['Average', 'Sum', 'Count', 'Minimum', 'Maximum']
                    : ['Count', 'Minimum', 'Maximum']
                return availableAggregationFunctions
            }
            return []
        },
        query() {
            const groupByFunctionStatement = this.addedAggregationConditions.length
                ? this.addedAggregationConditions
                      .map(condition => {
                          const aggregationFunctionString = `${this.aggregationFunctions[condition.aggFunction]}(${JSON.stringify(condition.column)})`
                          return condition.alias ? `${aggregationFunctionString} AS ${JSON.stringify(condition.alias)}` : aggregationFunctionString
                      })
                      .join(',\n ')
                : ''
            const fromStatement = `\nFROM\n ${JSON.stringify(this.selectedTable)}`
            const selectStatement =
                this.allColumnsAreSelected && !this.addedAggregationConditions.length
                    ? 'SELECT *' + fromStatement
                    : !this.allColumnsAreSelected && !this.addedAggregationConditions.length
                    ? `SELECT\n ${this.addedColumns.map(col => `${JSON.stringify(col)}`).join(',\n ')}` + fromStatement
                    : this.onlyAggregationWithoutGroupBy
                    ? `SELECT\n ${groupByFunctionStatement}` + fromStatement
                    : `SELECT\n ${this.addedColumns
                          .filter(col => !this.aggregationColumnNames.includes(col))
                          .map(col => `${JSON.stringify(col)}`)
                          .join(',\n ')},\n ${groupByFunctionStatement}` + fromStatement
            const orderByStatement =
                this.addedOrderingConditions.length && this.areOrderingConditionsValid
                    ? `\nORDER BY\n ${this.addedOrderingConditions
                          .map(condition => {
                              return `${/^(COUNT|AVG|SUM|MIN|MAX)\(.*/.test(condition.column) ? condition.column : JSON.stringify(condition.column)} ${
                                  condition.order
                              }`
                          })
                          .join(',\n ')}`
                    : ''
            const groupByFilterStatement =
                this.addedAggregationFilters.length && this.areAggregationFiltersValid
                    ? `\nHAVING\n ${this.addedAggregationFilters
                          .forEach(filter => {
                              const aggregationColumnName = this.aggregateConditionsColumnMappings.find(condition => condition.function === filter.column)
                              if (aggregationColumnName && this.selectedColumnsData.length) {
                                  const aggregationColumnType = this.selectedColumnsData.find(col => col.colName === aggregationColumnName.colName).type
                                  return `${/^(COUNT|AVG|SUM|MIN|MAX)\(.*/.test(filter.column) ? filter.column : JSON.stringify(filter.column)}  ${
                                      this.aggregationFilterOperators[filter.filter]
                                  }  ${
                                      this.numericColumns.includes(aggregationColumnType) || aggregationColumnName.aggregator === 'Count'
                                          ? filter.value
                                          : "'" + filter.value + "'"
                                  }`
                              }
                          })
                          .join('\n and ')}`
                    : ''
            const groupByColumnsStatement =
                this.addedAggregationConditions.length && !this.onlyAggregationWithoutGroupBy
                    ? `\nGROUP BY\n ${this.addedColumns
                          .filter(col => !this.aggregationColumnNames.includes(col))
                          .map(col => `${JSON.stringify(col)}`)
                          .join(',\n ')}${groupByFilterStatement}`
                    : ''
            const whereStatement = this.addedFilters.length
                ? '\nWHERE\n ' +
                  this.addedFilters
                      .forEach(filter => {
                          const dateAndNumericFilters = [
                              this.operatorTypes.EQUAL_OPERATOR,
                              this.operatorTypes.NON_EQUAL_OPERATOR,
                              this.operatorTypes.GREATER_THAN_OPERATOR,
                              this.operatorTypes.LESS_THAN_OPERATOR,
                              this.operatorTypes.GREATER_OR_EQUAL_OPERATOR,
                              this.operatorTypes.LESS_OR_EQUAL_OPERATOR
                          ]
                          if (dateAndNumericFilters.includes(filter.filter) && this.numericColumns.includes(this.colType(filter.column))) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} ${filter.inputField1}`
                          } else if (
                              [this.operatorTypes.EQUAL_OPERATOR, this.operatorTypes.NON_EQUAL_OPERATOR].includes(filter.filter) &&
                              filter.inputField1 &&
                              this.colType(filter.column) === this.columnTypes.STRING_COLUMN
                          ) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.inputField1.replace(/'/g, "''")}'`
                          } else if (dateAndNumericFilters.includes(filter.filter) && this.colType(filter.column) === this.columnTypes.DATE_COLUMN) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.datePicker1}'`
                          } else if (filter.filter === this.operatorTypes.BETWEEN_OPERATOR && this.colType(filter.column) === this.columnTypes.DATE_COLUMN) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.datePicker1}' and '${
                                  filter.datePicker2
                              }'`
                          } else if (dateAndNumericFilters.includes(filter.filter) && this.colType(filter.column) === this.columnTypes.TIME_COLUMN) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.timePicker1}'`
                          } else if (filter.filter === this.operatorTypes.BETWEEN_OPERATOR && this.colType(filter.column) === this.columnTypes.TIME_COLUMN) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.timePicker1}' and '${
                                  filter.timePicker2
                              }'`
                          } else if (dateAndNumericFilters.includes(filter.filter) && this.colType(filter.column) === this.columnTypes.TIMESTAMP_COLUMN) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.datePicker1} ${filter.timePicker1}'`
                          } else if (
                              filter.filter === this.operatorTypes.BETWEEN_OPERATOR &&
                              this.colType(filter.column) === this.columnTypes.TIMESTAMP_COLUMN
                          ) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} '${filter.datePicker1} ${
                                  filter.timePicker1
                              }' and '${filter.datePicker2} ${filter.timePicker2}'`
                          } else if (filter.filter === this.operatorTypes.BETWEEN_OPERATOR && this.numericColumns.includes(this.colType(filter.column))) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} ${filter.inputField1} and ${filter.inputField2}`
                          } else if (filter.filter === this.operatorTypes.IN_OPERATOR && filter.file) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} (${filter.fileContent})`
                          } else if (
                              filter.filter === this.operatorTypes.IN_OPERATOR &&
                              filter.inputField1 &&
                              !filter.file &&
                              this.numericColumns.includes(this.colType(filter.column))
                          ) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} (${filter.inputField1})`
                          } else if (
                              filter.filter === this.operatorTypes.IN_OPERATOR &&
                              filter.inputField1 &&
                              !filter.file &&
                              !this.numericColumns.includes(this.colType(filter.column))
                          ) {
                              return `${JSON.stringify(filter.column)} ${this.operatorsMappings[filter.filter]} (${filter.inputField1
                                  .split(',')
                                  .map(val => `'${val.replace(/'/g, "''")}'`)
                                  .join(', ')})`
                          } else if ([this.operatorTypes.NULL_OPERATOR, this.operatorTypes.NON_NULL_OPERATOR].includes(filter.filter)) {
                              return `${JSON.stringify(filter.column)} ${filter.filter}`
                          }
                      })
                      .join('\n AND ')
                : ''
            const limitStatement = this.onlyAggregationWithoutGroupBy ? '' : `\nLIMIT ${this.numberRows}`
            return selectStatement + whereStatement + groupByColumnsStatement + orderByStatement + limitStatement
        },
        columnData() {
            if (this.availableColumnSearch && this.allColumns) {
                return this.allColumns.filter(
                    column =>
                        column.colName.toLowerCase().includes(this.availableColumnSearch.toLowerCase()) ||
                        column.desc.toLowerCase().includes(this.availableColumnSearch.toLowerCase())
                )
            }
            return this.allColumns
        }
    },
    methods: {
        fetchTablePreviewData(tid) {
            this.fetchingPreviewData = true
            this.$axios
                .get(`tables/${tid}/preview`)
                .then(response => {
                    this.selectedTablePreviewData = response.data
                    this.selectedTablePreviewHeaders = Object.keys(response.data[0]).map(header => {
                        return { text: header.toLowerCase(), align: 'left', value: header }
                    })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to fetch table preview data!',
                        snackBarIcon: 'error',
                        snackBarTimeout: 5000
                    })
                })
                .finally(() => {
                    this.fetchingPreviewData = false
                })
        },
        resetInitialState: function () {
            Object.assign(this.$data, initialState())
        },
        addRemoveColumn(column) {
            if (!this.addedColumns.includes(column)) {
                this.addedColumns.push(column)
            } else {
                this.removeColumn(column)
            }
        },
        addAllColumns() {
            this.addedColumns = this.allColumns.map(col => col.colName)
        },
        removeColumn: function (column) {
            const index = this.addedColumns.indexOf(column)
            if (index > -1) {
                this.addedColumns.splice(index, 1)
            }
        },
        getAggregationColumnNames(aggFunction) {
            if (['Average', 'Sum'].includes(aggFunction)) {
                const filteredColumns = this.selectedColumnsData.filter(column => this.numericColumns.includes(column.type))
                return filteredColumns ? filteredColumns.map(column => column.colName) : []
            } else {
                return this.addedColumns
            }
        },
        getAggregationFilterColumnType(aggFunction) {
            const aggregationCondition = this.aggregateConditionsColumnMappings.find(condition => condition.function === aggFunction)
            const columnType = aggregationCondition ? this.selectedColumnsData.find(col => col.colName === aggregationCondition.colName).type : null
            return columnType
        },
        cleanAllQueryData() {
            this.addedColumns = []
            this.availableColumnSearch = ''
            this.addedColumnSearch = ''
            this.orderBySearch = ''
            this.addedFilters = []
            this.addedOrderingConditions = []
            this.addedAggregationConditions = []
            this.addedAggregationFilters = []
            this.aggregationFilterIndex = 0
            this.filterIndex = 0
            this.orderingColumnIndex = 0
            this.aggregationColumnIndex = 0
        },
        setTableInRouteQuery(tableName) {
            this.$router.push({
                query: { tab: this.tableViewTabNames.QUERYBUILDER, table: tableName }
            })
        },
        fetchColumnsForQuery: function (tableName) {
            if (tableName && this.currentTables.length && (!this.latestFetchedTable || tableName !== this.latestFetchedTable)) {
                this.selectedTable = tableName
                this.tableBeingFetched = tableName
                const tableId = this.currentTables.find(table => table.short_id === tableName).tid
                this.fetchingTableColumnsForQuery = true
                this.$axios
                    .get(`/tables/${tableId}/columns`)
                    .then(response => {
                        this.fetchTablePreviewData(tableId)
                        this.latestFetchedTable = tableName
                        this.cleanAllQueryData()
                        this.queryColumnsList = response.data
                    })
                    .catch(() => {
                        this.selectedTable = this.latestFetchedTable
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Failed to fetch table column data, try refreshing the page.',
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.fetchingTableColumnsForQuery = false
                    })
            }
        },
        uploadFile(evt, filterIdx) {
            if (evt !== undefined && evt !== null) {
                const reader = new FileReader()
                reader.readAsText(evt)
                reader.onload = () => {
                    this.setFileContentForFilter(reader.result, filterIdx, evt.name)
                }
            } else {
                this.clearFilterFileData(filterIdx)
            }
        },
        setFileContentForFilter(fileData, filterIdx, fileName) {
            this.addedFilters[filterIdx].fileError = false
            const filter = this.addedFilters[filterIdx]
            const fileSplitContent = fileData.split(/\r\n|\r|\n/)
            let formatterData
            if (filter && this.numericColumns.includes(this.colType(filter.column))) {
                formatterData = fileSplitContent.map(dataPoint => {
                    if (isNaN(dataPoint)) {
                        this.addedFilters[filterIdx].fileError = true
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: `Conversion failed for ${fileName}. Please check the content of the file and make sure that all values are numeric.`,
                            snackBarIcon: 'error',
                            snackBarTimeout: 10000
                        })
                        return `'${dataPoint}'`
                    } else {
                        return parseFloat(dataPoint)
                    }
                })
            } else {
                formatterData = fileSplitContent.map(dataPoint => `'${dataPoint}'`)
            }
            this.addedFilters[filterIdx].fileContent = formatterData
            this.addedFilters[filterIdx].inputField1 = formatterData
        },
        addFilter() {
            const id = this.filterIndex
            this.addedFilters.push({
                index: id,
                column: null,
                filter: null,
                inputField1: null,
                inputField2: null,
                datePicker1: null,
                datePicker2: null,
                timePicker1: null,
                timePicker2: null,
                file: null,
                fileContent: null,
                fileError: false,
                valid: false
            })
            this.filterIndex += 1
        },
        addOrderingCondition() {
            const id = this.orderingColumnIndex
            const defaultColumn = this.orderByColumnNames[0]
            this.addedOrderingConditions.push({
                index: id,
                column: defaultColumn,
                order: 'ASC',
                valid: false
            })
            this.orderingColumnIndex += 1
        },
        addAggregationCondition() {
            const id = this.aggregationColumnIndex
            const column = this.aggregationColumnNames.length ? this.aggregationColumnNames[0] : this.addedColumns[0]
            this.addedAggregationConditions.push({
                index: id,
                aggFunction: 'Count',
                column,
                alias: '',
                aggregationColumns: this.addedColumns,
                valid: false
            })
            this.aggregationColumnIndex += 1
        },
        addAggregationFilter() {
            const id = this.aggregationFilterIndex
            const conditionColumnType = this.getAggregationFilterColumnType(this.validAggregationConditions[0])
            this.addedAggregationFilters.push({
                index: id,
                filter: 'Equal',
                column: this.validAggregationConditions[0],
                value: null,
                valueType: conditionColumnType,
                valid: false
            })
            this.aggregationFilterIndex += 1
        },
        removeFilter(filterIndex) {
            this.addedFilters = this.addedFilters.filter(item => item.index !== filterIndex)
        },
        removeOrderingCondition(orderIndex) {
            this.addedOrderingConditions = this.addedOrderingConditions.filter(item => item.index !== orderIndex)
        },
        removeAggregationCondition(aggregatorIndex) {
            this.addedAggregationConditions = this.addedAggregationConditions.filter(item => item.index !== aggregatorIndex)
        },
        removeAggregationFilter(filterIndex) {
            this.addedAggregationFilters = this.addedAggregationFilters.filter(item => item.index !== filterIndex)
        },
        clearFilterDataUponColumnChange(filterArrayIndex) {
            this.addedFilters[filterArrayIndex].filter = null
            this.addedFilters[filterArrayIndex].inputField2 = null
            this.addedFilters[filterArrayIndex].datePicker1 = null
            this.addedFilters[filterArrayIndex].datePicker2 = null
            this.addedFilters[filterArrayIndex].timePicker1 = null
            this.addedFilters[filterArrayIndex].timePicker2 = null
            this.clearFilterFileData(filterArrayIndex)
        },
        clearFilterFileData(filterArrayIndex) {
            this.addedFilters[filterArrayIndex].fileContent = null
            this.addedFilters[filterArrayIndex].file = null
            this.addedFilters[filterArrayIndex].fileError = null
            this.addedFilters[filterArrayIndex].inputField1 = null
        },
        colType(col) {
            if (col && this.allColumns) {
                const colData = this.allColumns.find(item => item.colName === col)
                if (colData) {
                    return colData.type
                } else {
                    return null
                }
            }
        },
        columnFilters(col) {
            if (col && this.allColumns.length) {
                const colData = this.allColumns.find(item => item.colName === col)
                if ((colData && this.dateAndTimeColumns.includes(colData.type)) || this.numericColumns.includes(colData.type)) {
                    return Object.values(this.operatorTypes)
                } else if (colData) {
                    return [
                        this.operatorTypes.EQUAL_OPERATOR,
                        this.operatorTypes.NON_EQUAL_OPERATOR,
                        this.operatorTypes.IN_OPERATOR,
                        this.operatorTypes.NULL_OPERATOR,
                        this.operatorTypes.NON_NULL_OPERATOR
                    ]
                }
            } else {
                return []
            }
        },
        querySubmitted() {
            eventBus.$emit('querySubmitSuccess')
        }
    },
    mounted() {
        this.fetchColumnsForQuery(this.$route.query.table)
    },
    watch: {
        $route: {
            handler(to, from) {
                if (
                    from &&
                    from.params.snid &&
                    (!to || !to.params.snid || (to && to.params.snid && from.params.snid.toString() !== to.params.snid.toString()))
                ) {
                    this.resetInitialState()
                }
                if (
                    to.name === 'snapshot-tables' &&
                    to.query &&
                    to.query.tab === this.tableViewTabNames.QUERYBUILDER &&
                    !to.query.table &&
                    this.selectedTable
                ) {
                    this.$router.push({
                        query: { tab: this.tableViewTabNames.QUERYBUILDER, table: this.selectedTable }
                    })
                }
                if (to.name === 'snapshot-tables' && to.query && to.query.table && (!this.latestFetchedTable || to.query.table !== this.latestFetchedTable)) {
                    this.fetchColumnsForQuery(to.query.table)
                }
            }
        },
        addedColumns: function (nextVal) {
            this.addedAggregationConditions =
                nextVal.length < 2 && !this.allColumnsAreSelected
                    ? []
                    : this.addedAggregationConditions.length
                    ? this.addedAggregationConditions.filter(condition => nextVal.includes(condition.column))
                    : []
            this.addedFilters =
                !nextVal.length && !this.allColumnsAreSelected
                    ? []
                    : this.addedFilters.length
                    ? this.addedFilters.filter(filter => nextVal.includes(filter.column))
                    : []
        },
        currentTables: function (nextVal, preVal) {
            if (!preVal.length && nextVal.length > 0 && this.$route.query && this.$route.query.table && this.$route.query.table !== this.selectedTable) {
                this.fetchColumnsForQuery(this.$route.query.table)
            }
        },
        orderByColumnNames: function (nextVal) {
            this.addedOrderingConditions = !nextVal.length ? [] : this.addedOrderingConditions.filter(condition => nextVal.includes(condition.column))
        },
        allColumns: function (nextVal) {
            if (nextVal && nextVal.length && this.allColumnsAreSelected) {
                this.addedColumns = nextVal.map(column => column.colName)
            }
        },
        addedAggregationConditions: function (nextVal) {
            this.addedAggregationFilters = this.addedAggregationFilters.filter(filter => this.validAggregationConditions.includes(filter.column))
        },
        validAggregationConditions: function (nextVal) {
            this.addedAggregationFilters = this.addedAggregationFilters.filter(condition => nextVal.includes(condition.column))
        },
        onlyAggregationWithoutGroupBy(nextVal) {
            if (nextVal) {
                this.addedAggregationFilters = []
                this.addedOrderingConditions = []
            }
        }
    }
}
</script>
